<template>
  <div>
    <div class="mid_box">
      <img
        style="width: 15px"
        src="../../assets/images/Examine_img/back.png"
        alt=""
        @click="toBack"
      />
      <span style="margin-right: 15px" @click="toBack">返回</span>
      <el-button type="primary" class="edit" @click="toOpen(false)"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <el-button type="primary" class="edit" @click="dialogVisible1 = true"
        >新增提问<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <div class="toSave1" @click="confirmSave" v-show="isCheck">
        <img
          style="width: 15px"
          src="../../assets/images/Examine_img/sava.png"
          alt=""
        />
        <span>完成检查</span>
      </div>
      <div class="toSave1" v-if="isCheck" @click="toChange">
        <img
          style="width: 15px"
          src="../../assets/images/Examine_img/sava.png"
          alt=""
        />
        <span>已检查</span>
      </div>
      <div class="toSave1" v-else @click="toChange">
        <img
          style="width: 15px"
          src="../../assets/images/Examine_img/sava.png"
          alt=""
        />
        <span>未检查</span>
      </div>
      <span style="margin-top: 5px">
        <img
          src="../../assets/images/Examine_img/del1.png"
          alt=""
          @click="delItem"
        />
      </span>
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="getPdf()"
        >打印检查表</el-button
      >
      <el-button
        style="margin-left: 10px"
        type="primary"
        class="edit"
        @click="exportExcel2()"
        >导出检查表</el-button
      >
      <el-button type="primary" class="edit" @click="toOpen(true)"
        >添加题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
    </div>

    <el-table
      ref="multipleTable"
      v-if="isCheck"
      :data="tableData1.slice(start, end)"
      tooltip-effect="dark"
      style="width: 100%"
      :key="itemKey"
      :height="elementHeight"
      highlight-current-row
      border
      @select="selectRow"
      id="multipleTable"
      @select-all="selectAll"
      @row-click="handleRowClick"
      @cell-dblclick="handleCell"
      @selection-change="handleSelectionChange"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
        border: '1px solid #DCDCDC',
      }"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="200">
      </el-table-column>
      <el-table-column prop="question" label="问题"> </el-table-column>
      <el-table-column
        prop="auditResult"
        label="是否符合要求  "
        align="center"
        width="280"
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 0, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >
              不符合
            </div>
            <div
              @click="changeResult(scope.row, 2, '纠正')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              纠正
            </div>
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >
              改进
            </div>
            <div
              @click="changeResult(scope.row, 4, '不适用')"
              :class="{ auditactive: scope.row.auditResult === 4 }"
            >
              不适用
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="答案"
        v-if="this.isshowAns"
        prop="answer"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="查看答案" width="150" v-else>
        <template slot-scope="scope">
          <el-button
            type="primary"
            class="button"
            plain
            @click="isChoose(scope.row)"
            >点击查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-else
      ref="multipleTable"
      :data="tableData2.slice(start, end)"
      tooltip-effect="dark"
      style="width: 100%"
      :height="elementHeight"
      :key="itemKey"
      border
      highlight-current-row
      id="multipleTable"
      @select="selectRow"
      @select-all="selectAll"
      @row-click="handleRowClick"
      @cell-dblclick="handleCell"
      @selection-change="handleSelectionChange"
      :header-cell-style="{
        background: '#EDF0F7',
        color: '#000000',
      }"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="200">
      </el-table-column>
      <el-table-column prop="question" label="问题" width="400">
      </el-table-column>
      <el-table-column
        prop="auditResult"
        label="是否符合要求  "
        align="center"
        width="280"
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 0, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >
              不符合
            </div>
            <div
              @click="changeResult(scope.row, 2, '纠正')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              纠正
            </div>
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >
              改进
            </div>
            <div
              @click="changeResult(scope.row, 4, '不适用')"
              :class="{ auditactive: scope.row.auditResult === 4 }"
            >
              不适用
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        align="center"
        width="190
        "
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt="" />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span
              v-show="scope.row.imagesCount > 0"
              style="margin-right: 50%"
              >{{ scope.row.imagesCount }}</span
            >
            <img src="../../assets/images/Examine_img/picture.png" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="lawAsk"
        v-if="!this.isshowAns"
        label="审核意见（双击列表）"
        width="200"
      >
        <template slot-scope="scope">
          <input
            v-if="AreaAuditId === scope.row.id && columnID === scope.column.id"
            type="text"
            @focus="compare(scope.row.lawAsk)"
            @blur="Updata(scope.row, scope.row.lawAsk)"
            v-model="scope.row.lawAsk"
          />
          <span v-else>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="答案"
        v-if="this.isshowAns"
        prop="answer"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{ scope.row.answer }}</template>
      </el-table-column>
      <el-table-column label="查看答案" v-else prop="answer" width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            class="button"
            plain
            @click="isChoose(scope.row)"
            >点击查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage1"
      :page-sizes="[10, 20, 50]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="isCheck ? tableData1.length : tableData2.length"
    >
    </el-pagination>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="company"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属部门:</span>
        <el-select
          v-model="deptID"
          placeholder="请选择"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属岗位:</span>
        <el-select
          v-model="postID"
          placeholder="请选择"
          @change="gettitle"
          class="Company_choose"
          disabled
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目:</span>
          <div class="topic">
            <div>文件名称</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img src="../../assets/images/Examine_img/top.png" @click="isshow = !isshow" alt="" />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div style="display: flex" v-for="(item, index) in titlelist" :key="index">
              <span
                style="margin: 10px">&ensp;&ensp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <div class="topic1">
                <div>{{ item.fileName }}</div>
                <div class="Centernumbet">
                  <span>{{ item.titleCount }}</span>
                </div>
                <div id="number">
                  <img @click="sub(item)" src="../../assets/images/Examine_img/slices/del1.png" alt="" />
                  <input type="text" style="ime-mode: disabled" @keyup="test(item)" v-model="item.extractCount" />
                  <img @click="plus(item)" src="../../assets/images/Examine_img/slices/plus1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div> -->
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px; margin-left: 0.8%">抽取题目:&ensp;</span>
          <el-table
            :data="titlelist"
            @selection-change="selectionDraw"
            style="width: 100%"
            height="250"
          >
            <el-table-column prop="fileName" label="文件名称" width="150">
            </el-table-column>
            <el-table-column prop="fileNo" label="文件编号"> </el-table-column>
            <el-table-column prop="titleCount" label="总题数">
            </el-table-column>
            <el-table-column type="selection" width="55"> </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toReAdd">确定抽题</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增提问" :visible.sync="dialogVisible1" width="38%">
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <div style="margin: 20px; width: 60px">问题描述</div>
          <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="PostData.question"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddASK">确定提问</el-button>
        <el-button @click="dialogVisible1 = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="查看/添加图片"
      :visible.sync="dialogVisibleimg"
      width="40%"
    >
      <div>上传图片</div>
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="token"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>

        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div style="font-size: 18px">答案:{{ answer }}</div>
      <div style="font-size: 18px">图片:</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleAsk = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核结果" :visible.sync="dialogVisibleresult" width="35%">
      <el-form label-width="80px">
        <el-form-item label="审核结果:">
          <span style="margin-right: 20px">{{ state }}</span>
          <el-radio
            v-model="curRow.UnqualityType"
            v-show="state === '不符合'"
            label="轻微不符合"
            >轻微不符合</el-radio
          >
          <el-radio
            v-model="curRow.UnqualityType"
            v-show="state === '不符合'"
            label="严重不符合"
            >严重不符合</el-radio
          >
        </el-form-item>
        <el-form-item label="审核意见:">
          <el-input v-model="Adoption" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="隐患类别">
          <el-select v-model="remark" clearable placeholder="请选择">
            <el-option
              v-for="item in ['一般', '不可接受', '重复']"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="违反条款:"
          v-show="state === '不符合' || state === '改进' || state === '纠正'"
        >
          <el-input v-model="curRow.ViolatedTerms"></el-input>
        </el-form-item>
        <el-form-item label="上传照片:">
          <el-upload
            action=" https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :http-request="uploadFile"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已上传图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
            <el-image
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            >
            </el-image>
            <i
              class="el-icon-delete-solid"
              v-show="index === Curindex"
              @click="delImg(item)"
            ></i>
          </span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="toSure">确认</el-button>
      </span>
    </el-dialog>
    <el-table
      id="pdfDom"
      :data="isCheck ? tableData1 : tableData2"
      style="width: 100%; position: absolute; left: -100%; z-index: -999"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column type="index" label="序号" width="50"> </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="200">
      </el-table-column>
      <el-table-column prop="question" label="问题"> </el-table-column>
      <el-table-column
        prop="auditResult"
        label="是否符合要求  "
        v-if="!this.isshowAns"
        align="center"
        width="280"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div
              @click="changeResult(scope.row, 1, '符合')"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 0, '不符合')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >
              不符合
            </div>
            <div
              @click="changeResult(scope.row, 2, '纠正')"
              :class="{ auditactive: scope.row.auditResult === 2 }"
            >
              纠正
            </div>
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >
              改进
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="imagesCount"
        label="照片列表"
        align="center"
        width="190"
        v-if="!this.isshowAns"
      >
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt="" />
            <span v-show="scope.row.imagesCount === 0">请添加图片</span>
            <span
              v-show="scope.row.imagesCount > 0"
              style="margin-right: 50%"
              >{{ scope.row.imagesCount }}</span
            >
            <img src="../../assets/images/Examine_img/picture.png" alt="" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="answer"
        v-if="this.isshowAns"
        label="答案"
        width="250"
      >
      </el-table-column>
      <el-table-column prop="answer" v-else label="答案" width="250">
      </el-table-column>
      <el-table-column prop="answer" label="审核意见（双击列表）" width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.lawAsk }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleconfirmSave"
      width="20%"
      :before-close="handleClose"
    >
      <span>是否确认完成</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleconfirmSave = false">取 消</el-button>
        <el-button type="primary" @click="toSave">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put, baseURL } from '../../api/http'
import { compressImage } from '../../modules/CompressImage'
import { excelAutoWidth } from '../../modules/Utils'
import { exportExcel } from '../../modules/ExportExcel'

export default {
  data() {
    return {
      token: {},
      htmlTitle: '文件检查',
      elementHeight: 0,
      BUrl: '',
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleimg: false,
      dialogVisiblebig: false,
      dialogVisibleAsk: false,
      dialogVisibleresult: false,
      dialogVisibleconfirmSave: false,
      tableData: [],
      tableData1: [],
      tableData2: [],
      state: '',
      Adoption: '',
      Cid: null,
      excelAutoWidth: excelAutoWidth,
      isCheck: true,
      itemKey: '',
      textarea: '',
      titlelist: null,
      imgList: [],
      srcList: [],
      curRow: '',
      curNum: '',
      value: '',
      currentPage: 0,
      areaNo: this.$route.query.auditAreaNo,
      company: Number(this.$route.query.CpyID),
      deptID: Number(this.$route.query.deptID),
      postID: Number(this.$route.query.postId),
      number: '',
      url: '',
      imgSrc: '',
      showImg: false,
      options: this.$store.state.companyList,
      Curindex: '',
      AreaAuditId: '',
      columnID: '',
      option2: [],
      curlawask: '',
      isSave: true,
      radio: '',
      start: 0,
      end: 10,
      delQuestion: null,
      isshowAns: false,
      currentPage1: '',
      curSize: 10,
      option1: [],
      answer: '',
      PostData: {
        fileAuditId: this.$route.query.Id,
        questionId: 0,
        question: '',
        answer: '',
        lawAsk: '',
        imagesCount: 0,
      },
      remark: '',
      isAddQuestion: false,
      selectDrawVlue: [],
    }
  },
  mounted() {
    this.token = {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
    }
    this.BUrl = baseURL[process.env.NODE_ENV + '']
    this.getTdata()
    this.getCompany()
    this.getElementHeight()
  },
  methods: {
    async getCompany() {
      await get('/api/Department/GetNoTree?CompId=' + this.company)
        .then((res) => {
          this.option1 = res.data
        })
        .then(() => {})
      await get('/api/Area/GetList')
        .then((res) => {
          this.option2 = res.data
        })
        .then(() => {
          this.gettitle(this.areaNo)
        })
    },
    async getPost() {
      await get('/api/Post?CompId=' + this.company + '&DeptId=' + this.deptID)
        .then((res) => {
          this.option2 = res.data
        })
        .then(() => {
          get(
            '/api/FileAudit/GetTitleList?CompId=' +
              this.company +
              '&DeptId=' +
              this.deptID +
              '&PostId=' +
              this.postID +
              (this.isAddQuestion ? '&FileAuditId=' + this.$route.query.Id : '')
          ).then((res) => {
            console.log(res)
            this.titlelist = res.data
          })
        })
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('multipleTable').offsetTop + 60 + 52)
      })
    },
    getTdata() {
      get(
        '/api/FileAuditList/GetByFileAuditId?FileAuditId=' +
          this.$route.query.Id
      ).then((res) => {
        this.tableData = res.data
        this.tableData1 = this.tableData.filter((item) => {
          return item.auditResult === null
        })
        this.tableData2 = this.tableData.filter((item) => {
          return item.auditResult !== null
        })
      })
    },
    toOpen(isAddQuestion) {
      this.isAddQuestion = isAddQuestion
      if (this.tableData2.length != 0 && !isAddQuestion) {
        this.$message({
          type: 'warning',
          message: '已有题目审核过，不可再次次抽题',
        })
        return
      }
      this.getPost()
      // for (let index = 0; index < this.tableData.length; index++) {
      //   if (this.tableData[index].auditResult !== null) {
      //     return
      //   }
      // }
      this.dialogVisible = true
    },
    toSave() {
      this.isSave = true
      this.dialogVisibleconfirmSave = false
      this.tableData.forEach((e) => {
        if (e.auditResult === null) this.isSave = false
      })
      if (this.isSave) {
        var item = JSON.parse(sessionStorage.getItem('Fitem'))
        console.log(item)
        if (item.status === 2) {
          this.$message({
            type: 'success',
            message: '已经审核完成，请勿重复完成',
          })
          return
        }
        put('/api/FileAudit', item).then((res) => {
          console.log(res)
          this.$message({
            type: 'success',
            message: res.message,
          })
          this.isSave = true
          item.status = 2
          sessionStorage.setItem('Fitem', item)
        })
      } else {
        this.$message({
          type: 'info',
          message: '未审核完成',
        })
      }
    },
    toBack() {
      this.$router.go(-1)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = ''
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount
    },
    overimg(index) {
      this.Curindex = index
    },
    openImg(e) {
      this.Cid = e.id
      this.dialogVisibleimg = true
      this.getimg()
    },
    selectRow(e, v) {
      this.delQuestion = e
      this.$refs.multipleTable.setCurrentRow(v)
      this.AreaAuditId = v.id
      //this.handleRowClick(v);
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          console.log(e.id)
          this.$confirm('此操作将永久删除该图片', '是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              deleteMethod('/api/FileAuditListImage?Id=' + e.id).then(() => {
                this.getimg()
              })
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              })
            })
        }
      })
    },
    changeResult(row, num, state) {
      if (num >= 0) {
        this.state = state
        this.Cid = row.id
        this.curRow = row
        this.curNum = num
        this.dialogVisibleresult = true
        return
      }
      row.auditResult = num
      put('/api/FileAuditList', row).then((res) => {
        if (res.code != '200') {
          this.$message({
            type: 'error',
            message: res.message,
          })
          row.auditResult = null
          return
        }
        this.$message({
          type: 'success',
          message: res.code == 200 ? '保存成功' : res.message,
        })
        for (var i = 0; i < this.tableData1.length; i++) {
          if (this.tableData1[i].id === row.id) {
            this.tableData1.splice(i, 1)
          }
        }
        for (var k = 0; k < this.tableData2.length; k++) {
          if (this.tableData2[k].id === row.id) {
            return
          }
        }
        this.tableData2.push(row)
      })
    },
    toSure() {
      this.curRow.auditResult = this.curNum
      this.curRow.lawAsk = this.Adoption
      this.curRow.remark = this.remark

      put('/api/FileAuditList', this.curRow).then((res) => {
        this.$message({
          type: 'success',
          message: res.code == 200 ? '保存成功' : res.message,
        })
        if (res.code == 200) {
          for (var i = 0; i < this.tableData1.length; i++) {
            if (this.tableData1[i].id === this.curRow.id) {
              this.tableData1.splice(i, 1)
            }
          }
          for (var k = 0; k < this.tableData2.length; k++) {
            if (this.tableData2[k].id === this.curRow.id) {
              return
            }
          }
          this.tableData2.push(this.curRow)
        }
        //this.$refs.upload.clearFiles();
        this.tableData2.push(this.curRow)
        this.$refs.upload.clearFiles()
      })
      this.dialogVisibleresult = false
      this.srcList = []
      this.Adoption = ''
      this.remark = ''
    },
    selectAll(e) {
      this.delQuestion = e
    },
    toChange() {
      this.isCheck = !this.isCheck
      this.start = 0
      this.end = 10
      this.currentPage1 = 1
    },
    gettitle(e) {
      this.option2.forEach((i) => {
        if (i.areaNo === e) {
          get('/api/AreaAudit/GetTitleList?AreaId=' + i.id).then((res) => {
            this.titlelist = res.data
          })
        }
      })
    },
    compare(e) {
      this.curlawask = e
      console.log(this.curlawask)
    },
    Updata(row, e) {
      this.columnID = ''
      if (this.curlawask === e) return
      put('/api/FileAuditList', row).then((res) => {
        console.log(res)
        this.$message({
          type: 'success',
          message: res.code == 200 ? '更改成功' : res.message,
        })
        //this.getTdata()
      })
    },
    selectionDraw(e) {
      this.selectDrawVlue = e
    },
    toReAdd() {
      if (this.selectDrawVlue.length == 0) {
        this.$message({
          type: 'success',
          message: '未选择题目',
        })
        return
      }
      var url = ''
      var data = {}
      var fitem = JSON.parse(sessionStorage.getItem('Fitem'))
      if (this.isAddQuestion) {
        url = '/api/FileAudit/InsertFileAudit?FileAuditId=' //增量
        data = {
          jsonData: {
            data: JSON.stringify(this.selectDrawVlue),
          },
          fileAudit: {
            id: 0,
            companyId: String(this.company),
            companyName: fitem.companyName,
            deptId: fitem.deptId,
            deptName: fitem.deptName,
            postId: fitem.postId,
            postName: fitem.postName,
            remark: fitem.remark,
            ruleRemark: fitem.ruleRemark,
            chargeUserId: fitem.chargeUserId,
            planId: '',
            auditType: fitem.auditType,
            status: fitem.status,
            saveTime: new Date().toISOString(),
            completeTime: new Date().toISOString(),
            saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
            saveUserNo: JSON.parse(sessionStorage.getItem('userInfo')).userNo,
            saveUserName: JSON.parse(sessionStorage.getItem('userInfo'))
              .userName,
          },
        }
      } else {
        var url1 = '/api/FileAudit/InsertExtract?FileAuditId=' //第一次
        var url2 = '/api/FileAudit/AgainExtract?FileAuditId=' //重新
        url =
          this.tableData.length == 0 && this.tableData2.length == 0
            ? url1
            : url2
        data = { data: JSON.stringify(this.selectDrawVlue) }
      }
      post(
        url +
          this.$route.query.Id +
          '&CompId=' +
          this.company +
          '&DeptId=' +
          this.deptID +
          '&PostId=' +
          this.postID,
        data
      ).then((res) => {
        if (res.code == '400') {
          this.$message({
            type: 'success',
            message: res.message,
          })
          return
        }
        this.getTdata()
        this.$message({
          type: 'success',
          message: res.code == 200 ? '更改成功' : res.message,
        })
        this.dialogVisible = false
      })
    },
    exportExcel2() {
      this.start = 0
      this.end = this.tableData.length
      this.tableData1 = this.tableData
      this.tableData2 = this.tableData
      this.isshowAns = true
      this.$nextTick(() => {
        exportExcel('#pdfDom', '文件检查.xlsx')
        this.start = 0
        this.end = 10
        this.isshowAns = false
        this.getTdata()
      })
    },
    confirmSave() {
      this.dialogVisibleconfirmSave = true
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleClose() {},
    handlePictureCardPreview() {
      this.dialogVisiblebig = true
    },
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            // 压缩后比原来更大，则将原图上传
            //调接口上传图片到服务器
            this.uploadFileReq(e.file)
          } else {
            //result压缩结果
            // 压缩后比原来小，上传压缩后的
            //调接口上传图片到服务器
            this.uploadFileReq(result)
          }
        })
      }
    },
    uploadFileReq(file) {
      var formData = new FormData()
      formData.append('files', file)
      post('/api/UpLoadFiles/UploadImg', formData)
        .then((res) => {
          this.$refs.upload.clearFiles()
          if (res.code == 200) {
            var data = {
              fileAuditListtId: this.Cid,
              ImageRemark: '',
              ImageAddress: this.BUrl + '/uploads/' + res.value,
              saveUserId:
                JSON.parse(sessionStorage.getItem('userInfo')).id,
            }
            post('/api/FileAuditListImage', {
              data: JSON.stringify([data]),
            }).then(() => {
              this.$refs.upload.clearFiles()
              this.getimg()
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles()
          this.$message.error('上传失败')
        })
    },
    getimg() {
      this.srcList = []
      get('/api/FileAuditListImage/' + this.Cid).then((res) => {
        this.imgList = res.value
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr)
        })
      })
    },
    toAddASK() {
      this.dialogVisible1 = false
      post('/api/FileAuditList?AuditType=文件审核', this.PostData).then(() => {
        this.getTdata()
      })
    },
    toPostImg() {
      this.dialogVisibleimg = false
      this.$refs.upload.clearFiles()
      this.getTdata()
      //this.$refs.upload.clearFiles();
    },
    handleCell(row, column) {
      this.columnID = column.id
    },
    togetArea(e) {
      get('/api/Area/GetByCompId?CompId=' + e).then((res) => {
        this.option2 = res.data
      })
    },
    delItem() {
      if (this.delQuestion.length == 0) {
        this.$message({
          type: 'info',
          message: '未选择题目',
        })
        return
      }
      var idList = []
      this.delQuestion.forEach((element) => {
        idList.push({ id: element.id })
      })
      this.$confirm(
        '此操作将永久删除所有打勾的题目' + this.delQuestion.length + '道',
        '是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          post('/api/FileAuditList/BlatchDelete', {
            data: JSON.stringify(idList),
          }).then((res) => {
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.getTdata()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    handleSelectionChange(value) {
      this.handleSelectionList = value
    },

    handleRowClick(row) {
      this.AreaAuditId = row.id

      //this.selectChange(this.handleSelectionList);
    },
    isChoose(e) {
      this.srcList = []
      this.answer = e.answer
      this.dialogVisibleAsk = true
      if (e.questionImageAddr) {
        var imgdata = e.questionImageAddr.split(',')
        console.log(imgdata)
        imgdata.forEach((e) => {
          this.srcList.push(this.BUrl + '/uploads/' + e)
        })
      }
    },
    changeRemark() {},
  },
}
</script>

<style scoped>
@import '../../assets/style/Examine_CSS.css';
</style>
